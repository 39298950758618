<template>
  <div class="feeback">
    <TopBar title="反馈">
      <BackIcon></BackIcon>
    </TopBar>
    <v-container class="px-5">
      <TopInfo :title="title" :img="imgPath"></TopInfo>
      <div class="descs" v-for="(desc, i) in descs" :key="i">
        <div class="text-subtitle-1 mt-5">{{ desc.title }}</div>
        <div class="text-subtitle-1 grey--text">{{ desc.text }}</div>
      </div>
      <v-chip class="mt-10" label @click="feeBack">
        <v-icon left> mdi-qqchat </v-icon>
        加入QQ群
      </v-chip>
    </v-container>
    <!-- 根据屏幕尺寸来判断是否需要OverFlowY -->
    <OverFlowY v-if="!isLargeScreen"></OverFlowY>
  </div>
</template>

<script>
import { isLargeScreenMixin, setChildViewMixin } from "common/mixin"
import feeback from 'assets/img/feeback.svg'
import feebackDark from 'assets/img/feebackDark.svg'

export default {
  name: "FeeBack",
  mixins: [isLargeScreenMixin, setChildViewMixin],
  data() {
    return {
      title: "反馈",
      descs: [
        {
          title: "遇到问题？",
          text:
            "可以尝试自己手动解决，据不完全统计：重启可解决99%的已知问题(笑",
        },
        {
          title: "还没有解决？",
          text: "可通过下方加入QQ群反馈。",
        },
        {
          title: "有好的建议？",
          text: "可通过下方加入QQ群反馈。",
        },
        {
           title: "感到无聊？",
           text: "可通过下方加入QQ群谈人生理想 :b)",
        }
      ],
    }
  },
  methods: {
    feeBack() {
      window.open('https://jq.qq.com/?_wv=1027&k=cP22QYXi')
    }
  },
  computed: {
    imgPath() {
      return this.isDark ? feebackDark : feeback
    }
  }
}
</script>

<style scoped>
</style>